@keyframes blinking {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.custom-paragraph-skeleton {
  animation: blinking 1s ease-in-out infinite;
}
.heading {
    height: "40px";
    margin-bottom: "20px";
    background-color: "#e0e0e0";
    border-radius: "4px";
}
.text {
  height: "10px";
  margin-bottom: "10px";
  background-color: "#e0e0e0";
  border-radius: "4px";
}